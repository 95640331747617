import { Navigate, useParams } from "react-router-dom"
import exercises from "../components/exercises/exercises.json"
import setup from "../components/exercises/setup.json"
import { PromptPage } from "../components/exercises/prompt-page"

export function CoachPage() {
    const { id } = useParams()
    const exercise = exercises.find((exercise) => exercise.id === id)
    const step = setup.find((setup) => setup.id === id)

    if (exercise) {
        return <PromptPage prompt={exercise} />
    }
    if (step) {
        return <PromptPage prompt={step} />
    }
    return <Navigate to="/" />
}
