import * as React from "react"
import { Navigate } from "react-router-dom"

import { MarketingApp } from "./app/marketing-app"
import { MarketingLayout } from "./app/marketing-layout"
// import { LogoDark, LogoLight } from "../dreammore/components/logo"

import { MarketingApp as MarketingAppShell } from "./app-shell/marketing-app"

import { useMarketingThemes } from "./use-marketing-themes"
import { useMarketingStrings } from "./use-marketing-strings"
import { useMarketingMenuItems } from "./use-marketing-menu-items"

import { Logos } from "./components/logo"

import { HomePage } from "./pages/home-page"
import { CurriculumPage } from "./pages/curriculum-page"
import { TestimonialsPage } from "./pages/testimonials-page"
import { TestimonialItemPage } from "./pages/testimonial-item-page"
import { BlogPage } from "./pages/blog-page"
import { BlogPostPage } from "./pages/blog-post-page"
import { BannerPage } from "./pages/banner-page"
import { ChildModelRecruitBannerPage } from "./pages/child-model-recruit-banner-page"
import { AgreementPage } from "./pages/boilerplate/agreement-page"
import { CoachPage } from "./pages/coach-page"
// import { PersonalitySurveyPage } from "./pages/personality-survey-page"
// import { PersonalityReportPage } from "./pages/personality-report-page"
// import { NotFoundPage } from "./pages/not-found-page"

// const CurriculumPage = React.lazy(() => import("./pages/curriculum-page"))
// const TestimonialsPage = React.lazy(() => import("./pages/testimonials-page"))


export function useMarketing() {

    const name = "marketing"

    const defaultPath = "/"
    const layout = <MarketingLayout/>
    const variant = "brand"

    const { themes } = useMarketingThemes()
    const { strings } = useMarketingStrings()
    const { menuItems } = useMarketingMenuItems()

    const collections = {
        // users: useUsers()
    }

    const routes = [
        {
            label: "Home",
            path: "/"
        },
        {
            label: "Curriculum",
            path: "/curriculum"
        },
        {
            label: "성공스토리",
            path: "/testimonials",
            labelStyle: { fontFamily: "'Noto Sans KR'"}
        },
        {
            label: "Blog",
            path: "/blog"
        },
        {
            label: "Agreement",
            path: "/terms",
            footerOnly: true,
        },
        {
            label: "Privacy Policy",
            path: "/terms",
            footerOnly: true
        }
    ]

    const publicChildRoutes = [
        {
            index: true,
            element: <HomePage/>,
        },
        {
            path: "curriculum",
            element: <CurriculumPage/>,
        },
        {
            path: "testimonials",
            element: <TestimonialsPage/>,
        },
        {
            path: "testimonials/:tid",
            element: <TestimonialItemPage/>,
        },
        {
            path: "blog",
            element: <BlogPage/>,
        },
        {
            path: "blog/:id",
            element: <BlogPostPage/>,
        },
    ]

    const publicRoutes = [
        {
            path: "/",
            element: <MarketingApp routes={routes}/>,
            children: publicChildRoutes,
        },
        {
            path: "/new",
            element: <MarketingAppShell menuItems={menuItems} prefix={"/new"} />,
            children: publicChildRoutes
        },
        {
            path: "/n", // naver
            element: <MarketingApp routes={routes} prefix={"/n"}/>,
            children: publicChildRoutes,
        },
        {
            path: "/n/b", // naver blog
            element: <MarketingApp routes={routes} prefix={"/n/b"}/>,
            children: publicChildRoutes,
        },
        {
            path: "/n/a", // naver powerlink ad
            element: <MarketingApp routes={routes} prefix={"/n/a"}/>,
            children: publicChildRoutes,
        },
        {
            path: "/k", // kakao
            element: <MarketingApp routes={routes} prefix={"/k"}/>,
            children: publicChildRoutes,
        },
        {
            path: "/ch", // kakao channel
            element: <MarketingApp routes={routes} prefix={"/ch"}/>,
            children: publicChildRoutes,
        },
        {
            path: "/i/e", // instagram ad - elmarket
            element: <MarketingApp routes={routes} prefix={"/i/e"}/>,
            children: publicChildRoutes,
        },
        {
            path: "/i/em", // instagram ad - el_market31 account
            element: <MarketingApp routes={routes} prefix={"/i/em"}/>,
            children: publicChildRoutes,
        },
        {
            path: "/elmarket", // instagram - elmarket profile
            element: <MarketingApp routes={routes} prefix={"/elmarket"}/>,
            children: [
                {
                    index: true,
                    element: <BannerPage mallUrl={"https://dreammore-shop.kr"} path={"elmarket"}/>,
                },
                {
                    path: "curriculum",
                    element: <CurriculumPage/>,
                },
                {
                    path: "testimonials",
                    element: <TestimonialsPage/>,
                },
                {
                    path: "testimonials/:tid",
                    element: <TestimonialItemPage/>,
                },
                {
                    path: "blog",
                    element: <BlogPage/>,
                },
                {
                    path: "blog/:id",
                    element: <BlogPostPage/>,
                },
            ],
        },
        {
            path: "/mozzimong", // instagram - mozzimong profile
            element: <MarketingApp routes={routes} prefix={"/mozzimong"} />,
            children: [
                {
                    index: true,
                    element: <BannerPage mallUrl={"https://mozzimong.dreammore-shop.kr"} path={"mozzimong"}/>,
                },
                {
                    path: "curriculum",
                    element: <CurriculumPage/>,
                },
                {
                    path: "testimonials",
                    element: <TestimonialsPage/>,
                },
                {
                    path: "testimonials/:tid",
                    element: <TestimonialItemPage/>,
                },
                {
                    path: "blog",
                    element: <BlogPage/>,
                },
                {
                    path: "blog/:id",
                    element: <BlogPostPage/>,
                },
            ],
        },
        {
            path: "/i/m", // instagram ad - mozzimong
            element: <MarketingApp routes={routes} prefix={"/i/m"}/>,
            children: [
                {
                    index: true,
                    element: <ChildModelRecruitBannerPage mallUrl={"https://www.instagram.com/mozzimong_bebe/"} path={"i/m"}/>,
                },
                {
                    path: "curriculum",
                    element: <CurriculumPage/>,
                },
                {
                    path: "testimonials",
                    element: <TestimonialsPage/>,
                },
                {
                    path: "testimonials/:tid",
                    element: <TestimonialItemPage/>,
                },
                {
                    path: "blog",
                    element: <BlogPage/>,
                },
                {
                    path: "blog/:id",
                    element: <BlogPostPage/>,
                },
            ],
        },
        {
            path: "/sm", // shopping mall
            element: <MarketingApp routes={routes} prefix={"/sm"}/>,
            children: publicChildRoutes,
        },
        {
            path: "/privacy",
            element: <AgreementPage/>
        },
        {
            path: "/terms",
            element: <AgreementPage/>
        },
        {
            path: "/agreement",
            element: <AgreementPage/>
        },
        // {
        //     path: "/404",
        //     element: <NotFoundPage />
        // },
        {
            path: "/coach/:id",
            element: <CoachPage />,
        },
        // {
        //     path: "/personality",
        //     children: [
        //         {
        //             index: true,
        //             element: <PersonalitySurveyPage />,
        //         },
        //         {
        //             path: "report",
        //             element: <PersonalityReportPage />,
        //         }
        //     ]
        //
        // },
        {
            path: "*",
            element: <Navigate to={"/"}/>
        },
    ]

    // const [ logo, /* setLogo */ ] = React.useState({
    //     dark: <LogoDark/>,
    //     light: <LogoLight/>,
    //     login: <LogoLight/>,
    //     loading: <LogoLight/>,
    //
    //     defaultLogo: <LogoLight/>,
    // })

    const logo = {
        auth: <Logos.LogoDark />,
        loading: <Logos.LogoDark />,
        navHeader: <Logos.PlainLogoDark imgProps={{ maxH: { base: "35px", lg: "65px" } }} />,
        defaultLogo: <Logos.LogoDark />,
    }

    const getLogo = name => logo[name] || logo.defaultLogo

    const [ constants, /* setConstants */ ] = React.useState({
        auth: {
            signupPage: true,
            loginPage: true,
            requiredClaims: [ "super", "admin", "manager", "instructor", "owner", "member" ],
            defaultAuthRoute: "login",
            usersCol: "users"
        },
        components: {},
        logo,
        getLogo,
        site: {},
        strings
    })

    const ui = {
        accentColor: [ 'brand.500', 'brand.300' ],
        mutedColor: [ 'whiteAlpha.900', 'whiteAlpha.800' ],
        activeColor: [ 'white', 'brand.500' ],
    }


    return {
        name,
        defaultPath,
        layout,
        collections,
        publicRoutes,
        getLogo,
        constants,
        ui,
        themes,
        variant
    }
}