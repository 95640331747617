import * as React from "react"
import { Box, Button, Stack } from "@chakra-ui/react"
import { transparentize } from "@chakra-ui/theme-tools"

import { OptimizedImage } from "../../../components/optimized-image/optimized-image"

import { kakao } from "../kakao/config"
import { naver } from "../naver/config"

import imgs from "../../assets/icons/min"

export function CallToActionButton({ img, bg, color, text, onClick, href, imgProps, buttonProps = {} }) {

    if (href) buttonProps = { ...buttonProps, as: "a", href }
    else if (onClick) buttonProps = { ...buttonProps, onClick }

    // console.log(buttonProps)
    // console.log(imgProps)
    //
    // return null

    return <Button
        className={"call-to-action-button"}
        minW={{ base: "inherit", md: "xs" }}
        bg={bg}
        _hover={{ bg: transparentize(bg, .8) }}
        color={color}
        rounded={"md"}
        shadow={"md"}
        h={"2.75rem"}
        fontSize={"lg"}
        size={"lg"}
        {...buttonProps}
    >
        <OptimizedImage
            img={img}
            imgProps={{
                className: "call-to-action-button-img",
                w: "30px",
                mr: 3,
                ...imgProps,
            }}
        />

        {text}
    </Button>
}

export function CallToActionStack() {

    const chatUrl = kakao.getChatUrl()
    const blogUrl = naver.blogUrl

    return <Stack
        className={"call-to-action-container"}
        direction={{ base: "column", md: "row-reverse" }}
        w={"full"}
        maxW={"3xl"}
        justify={"center"}>
        <CallToActionButton
            img={imgs.kakaoTalk}
            imgProps={{ alt: "카카오 로고"}}
            bg={"kakao"}
            color={"gray.700"}
            text={"창업무료상담하기"}
            href={chatUrl}
            buttonProps={{ className: "call-to-action-button contact-us contact-us-button"}}
        />
        <CallToActionButton
            img={imgs.naverBlog}
            imgProps={{ alt: "네이버 블로그"}}
            bg={"naver"}
            color={"white"}
            text={"네이버 블로그 바로가기"}
            href={blogUrl}
            buttonProps={{ className: "call-to-action-button read-naver-blog"}}
        />
    </Stack>
}

export function CallToAction() {

    return <Box
        className={"call-to-action-section"}
        as={"section"}
        w={"full"}
        p={2}
        maxW={"3xl"}
        mx={"auto"}>
        <CallToActionStack />
    </Box>
}